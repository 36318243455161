@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

::selection {
  background-color: #FF695C;
  color: #000;
}

hr {
  @apply dark:border-gray-700;
}
